class window.ViewModel
  constructor: () ->
    @login_button_show = ko.observable(true)

    @login_click = (data) =>
      @login_button_show(false)
      window.location.href = data;
      return true


# ******************************************************************************
# Setup Knockout.js ViewModel
# ******************************************************************************

# this will break firefox bfcache
#window.onunload = () ->
## This is an empty function

window.onpageshow = (event) ->
    if event.persisted
        location.reload()


window.viewmodel = new window.ViewModel()
$ ->
  ko.applyBindings(window.viewmodel)
  window.viewmodel.login_button_show(true)

